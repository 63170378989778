<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg"></DlgMessage>
        <DlgCustChangePassword ref="dlgChangePassword"></DlgCustChangePassword>
        <DlgCustChangePIN ref="dlgChangePIN"></DlgCustChangePIN>
        <DlgCustIssuance ref="dlgCustIssuance"></DlgCustIssuance>
        <DlgCustActivate ref="dlgCustActivate" :visible="dlgVisible.activate" @close="dlgCustClose('activate')"></DlgCustActivate>
        <DlgCustAddress ref="dlgCustAddress" :visible="dlgCustAddressVisible" @close="dlgCustAddressClose"></DlgCustAddress>
        <DlgConfirm ref="confirm" />
        <div class="pa-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Customer Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
            <v-card>
                <v-toolbar flat color="panel1" dark>
                    <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-menu</v-icon>
                        </v-btn>
                        </template>
                        <v-list>
                            <!--v-list-item v-for="menu in menus" :key="menu.title" router :to="menu.route+form.merchant_id+'/'+ encMerchantName"-->
                            <v-list-item v-for="menu in menus" :key="menu.title" @click="menuClick(menu.type)">
                                <v-list-item-icon>
                                    <v-icon>{{ menu.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ menu.title }}</v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </v-menu>
                    <v-toolbar-title>Profile</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text class="mr-2" @click="frefresh"><v-icon>mdi-refresh</v-icon></v-btn>
                    <v-btn text class="mr-2" @click="fsave"><v-icon>save</v-icon>Save</v-btn>
                </v-toolbar>
            </v-card>
            <v-tabs vertical>
                <v-tab @click="tabClickHandler(1)"><v-icon left>mdi-account</v-icon>Profile</v-tab>
                <v-tab @click="tabClickHandler(2)"><v-icon left>mdi-account-box</v-icon>Contact</v-tab>
                <v-tab @click="tabClickHandler(3)"><v-icon left>mdi-map-marker</v-icon>Address</v-tab>
                <v-tab @click="tabClickHandler(4)"><v-icon left>mdi-wallet-membership</v-icon>Membership</v-tab>
                <v-tab @click="tabClickHandler(5)"><v-icon left>mdi-account-details</v-icon>Demography</v-tab>
                <v-tab @click="tabClickHandler(6)"><v-icon left>mdi-account-multiple</v-icon>Account No</v-tab>
                <v-tab @click="tabClickHandler(7)"><v-icon left>mdi-information</v-icon>Extra Info</v-tab>
                <v-tab @click="tabClickHandler(8)"><v-icon left>mdi-sale</v-icon>Transactions</v-tab>
                <v-tab @click="tabClickHandler(9)"><v-icon left>mdi-gift</v-icon>Redemptions</v-tab>
                <v-tab @click="tabClickHandler(10)"><v-icon left>mdi-history</v-icon>History</v-tab>
                <v-tab @click="tabClickHandler(11)"><v-icon left>mdi-desktop-classic</v-icon>System</v-tab>

                <v-tab-item ref="account">
                    <v-card flat>
                        <v-form ref="formAccount" class="pa-5">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field label="Point Balance" disabled outlined v-model="form.point_balance"></v-text-field>
                                    <v-text-field label="Full Name" outlined v-model="form.full_name"></v-text-field>
                                    <v-text-field label="IC Number" outlined v-model="form.new_ic_no"></v-text-field>
                                    <v-text-field label="Passport No." outlined v-model="form.passport_no"></v-text-field>
                                    <v-text-field label="Date of Birth" outlined v-model="form.dob"></v-text-field>
                                    
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-tab-item>

                <v-tab-item ref="contact">
                    <v-card flat>
                        <v-form ref="formContact" class="pa-5">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field label="Email Address" outlined v-model="form.email"></v-text-field>
                                    <v-text-field label="Cell Phone No." outlined v-model="form.cell_phone"></v-text-field>
                                    <v-text-field label="Work Phone No." outlined v-model="form.work_phone"></v-text-field>
                                    <v-text-field label="Home Phone No." outlined v-model="form.home_phone"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-tab-item>

                <v-tab-item ref="address">
                    <v-card flat>
                        <v-list>
                            <template v-for="(address,index) in addressList">
                            <v-list-item four-line :key="'key-' + index">
                                <v-list-item-content>
                                    <v-list-item-title v-if ="address.default == 1">{{address.alias}} (*)</v-list-item-title>
                                    <v-list-item-title v-else>{{address.alias}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                    {{address.attention_to}},
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                    {{address.address1}} {{address.address2}} {{address.address3}},
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                    {{address.city}} {{address.postal_code}} {{address.state}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn small icon dark class="primary" @click="dlgCustAddressShow(index)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn small icon dark class="error"  @click="dlgCustAddressDelete(index)">
                                        <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                
                            </v-list-item-action>
                                </v-list-item>
                                <v-divider  :key="'keyd-' + index"></v-divider>
                                </template>
                                <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                </v-list-item-title>
                            </v-list-item-content>
                            
                            <v-list-item-action>
                                <v-btn icon small dark class="success" @click="dlgCustAddressShow(-1)">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        </v-list>
                    </v-card>
                </v-tab-item>


                <v-tab-item ref="membership">
                    <v-card flat>
                        <v-form ref="formMembership" class="pa-5">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field disabled label="Login ID" outlined v-model="form.login_id"></v-text-field>
                                    <v-text-field ref="formPassword" label="Password" outlined v-model="form.password" 
                                    type="password"
                                    disabled                                    
                                    ></v-text-field>
                                    <v-text-field disabled label="PIN" outlined v-model="form.pin"></v-text-field>
                                    <v-text-field label="Membership No." outlined v-model="form.mbr_no"></v-text-field>
                                    <v-text-field label="Card No." outlined v-model="form.mbr_card_no"></v-text-field>
                                    <v-select
                                        v-model="form.mbr_type"  
                                        :items="memberParam['mbr_type']"
                                        item-text="description"
                                        item-value="code"
                                        label="Type"
                                        standard
                                        outlined
                                        
                                    ></v-select>
                                    

                                    <v-text-field disabled label="Verification Code" outlined v-model="form.verification_code"></v-text-field>
                                    <v-text-field disabled label="Verification Date" outlined v-model="form.verified_date"></v-text-field>
                                    <v-text-field disabled label="Verification Status" outlined v-model="form.verification_status"></v-text-field>
                                    <v-divider></v-divider>
                                    <v-text-field disabled label="Registration Date" outlined v-model="form.mbr_registration_date"></v-text-field>
                                    <v-text-field disabled label="Termination Date" outlined v-model="form.mbr_termination_date"></v-text-field>
                                    <v-text-field readonly label="Expiry Date" outlined v-model="form.mbr_expiry_date" append-outer-icon="mdi-account-reactivate" @click:append-outer="renewal"></v-text-field>
                                    <v-text-field disabled label="Status" outlined v-model="form.mbr_status"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item ref="demography">
                    <v-card flat>
                        <v-form ref="formDemography" class="pa-5">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-select
                                        v-for="df in demographyFields"
                                        :key="df.name"
                                        v-model="form[df.field]"  
                                        :items="memberParam[df.optionList]"
                                        item-text="description"
                                        item-value="code"
                                        :label="df.text"
                                        standard
                                        outlined
                                        
                                    ></v-select>

                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-tab-item>

                <v-tab-item ref="account">
                    <v-card flat>
                        <v-form ref="formAccount" class="pa-5">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field :label="'Account-01 (' + memberParam['map_cust_account'][0]['description'] + ')'" outlined v-model="form.account_01"></v-text-field>
                                    <v-text-field :label="'Account-02 (' + memberParam['map_cust_account'][1]['description'] + ')'" outlined v-model="form.account_02"></v-text-field>
                                    <v-text-field :label="'Account-03 (' + memberParam['map_cust_account'][2]['description'] + ')'" outlined v-model="form.account_03"></v-text-field>
                                    <v-text-field :label="'Account-04 (' + memberParam['map_cust_account'][3]['description'] + ')'" outlined v-model="form.account_04"></v-text-field>
                                    <v-text-field :label="'Account-05 (' + memberParam['map_cust_account'][4]['description'] + ')'" outlined v-model="form.account_05"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item ref="extrainfo">
                    <v-card flat>
                        <v-form ref="formExtraInfo" class="pa-5">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field :label="'Extra Info-01 (' + memberParam['map_cust_extra_info'][0]['description'] + ')'" outlined v-model="form.extra_info_01"></v-text-field>
                                    <v-text-field :label="'Extra Info-01 (' + memberParam['map_cust_extra_info'][1]['description'] + ')'" outlined v-model="form.extra_info_02"></v-text-field>
                                    <v-text-field :label="'Extra Info-01 (' + memberParam['map_cust_extra_info'][2]['description'] + ')'" outlined v-model="form.extra_info_03"></v-text-field>
                                    <v-text-field :label="'Extra Info-01 (' + memberParam['map_cust_extra_info'][3]['description'] + ')'" outlined v-model="form.extra_info_04"></v-text-field>
                                    <v-text-field :label="'Extra Info-01 (' + memberParam['map_cust_extra_info'][4]['description'] + ')'" outlined v-model="form.extra_info_05"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item ref="transactions">
                    <v-form ref="formSearchCustTransaction">
                    <v-row class="mx-1 my-2">
                        <v-col cols="12" md="3">
                            <v-select
                                v-model="txSearch.key"
                                :items="searchKeyList"
                                item-text="description"
                                item-value="code"
                                label="Search Key"
                                standard
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-menu v-model="txSearch.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        prepend-icon="mdi-calendar"
                                        outlined
                                        dense
                                        :value="txSearch.dateFrom"
                                        label="Date From"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="txSearch.dateFrom" @change="txSearch.menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="3">
                            
                            <v-menu v-model="txSearch.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        prepend-icon="mdi-calendar"
                                        outlined
                                        dense
                                        :value="txSearch.dateTo"
                                        label="Date To"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="txSearch.dateTo" @change="txSearch.menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select
                                v-model="txSearch.limit"
                                :items="dataLimitList"
                                item-text="description"
                                item-value="code"
                                label="Data Limit"
                                standard
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-btn icon condensed color="panel1" @click="searchCustTransaction()">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>                
                    </v-row>
                    </v-form>
                    <div class="pa-3">
                    <v-card>
                    <v-data-table
                    item-key="created_date"
                    :headers="txSearch.tblHeaders"
                    :items="txSearch.tblBody"
                    :search="txSearch.tblSearch"
                    :loading="txSearch.loadingFlag"
                    loading-text="Loading... Please wait" 
                    @click:row="openTxDetail"   
                    ></v-data-table>
                    </v-card>
                    </div>                    

                </v-tab-item>
                <v-tab-item ref="redemptions">
                    <v-form ref="formSearchCustRedemption">
                    <v-row class="mx-1 my-2">
                        <v-col cols="12" md="3">
                            <v-select
                                v-model="rdSearch.key"
                                :items="searchRdKeyList"
                                item-text="description"
                                item-value="code"
                                label="Search Key"
                                standard
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-menu v-model="rdSearch.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        prepend-icon="mdi-calendar"
                                        outlined
                                        dense
                                        :value="rdSearch.dateFrom"
                                        label="Date From"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="rdSearch.dateFrom" @change="rdSearch.menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="3">
                            
                            <v-menu v-model="rdSearch.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        prepend-icon="mdi-calendar"
                                        outlined
                                        dense
                                        :value="rdSearch.dateTo"
                                        label="Date To"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="rdSearch.dateTo" @change="rdSearch.menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select
                                v-model="rdSearch.limit"
                                :items="dataLimitList"
                                item-text="description"
                                item-value="code"
                                label="Data Limit"
                                standard
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-btn icon condensed color="panel1" @click="searchCustRedemption()">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>                
                    </v-row>
                    </v-form>
                    <div class="pa-3">
                    <v-card>
                    <v-data-table
                    item-key="created_date"
                    :headers="rdSearch.tblHeaders"
                    :items="rdSearch.tblBody"
                    :search="rdSearch.tblSearch"
                    :loading="rdSearch.loadingFlag"
                    loading-text="Loading... Please wait" 
                    @click:row="openRdDetail"   
                    ></v-data-table>
                    </v-card>
                    </div>                    
                </v-tab-item>
                <v-tab-item ref="history">
                    <v-form ref="formSearchCustHistory">
                    <v-row class="mx-1 my-2">
                        <v-col cols="12" md="3">
                            <v-menu v-model="histSearch.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        prepend-icon="mdi-calendar"
                                        outlined
                                        dense
                                        :value="histSearch.dateFrom"
                                        label="Date From"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="histSearch.dateFrom" @change="histSearch.menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="3">
                            
                            <v-menu v-model="histSearch.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        prepend-icon="mdi-calendar"
                                        outlined
                                        dense
                                        :value="histSearch.dateTo"
                                        label="Date To"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="histSearch.dateTo" @change="histSearch.menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="1">
                                <v-btn icon condensed color="panel1" @click="searchCustHistory()">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>                
                    </v-row>
                    </v-form>
                    <div class="pa-3">
                    <v-card>
                        <v-data-table
                        item-key="created_date"
                        :headers="histSearch.tblHeaders"
                        :items="histSearch.tblBody"
                        :search="histSearch.tblSearch"
                        :loading="histSearch.loadingFlag"
                        loading-text="Loading... Please wait" 
                        ></v-data-table>
                    </v-card>
                    </div>                    

                </v-tab-item>
                <v-tab-item ref="system">
                    <v-card flat>
                        <v-form ref="mainForm">
                            <v-list two-line>
                                <v-list-item  v-for="(info,index) in  systemInfo" :key="'infoRow'+index">
                                    <v-list-item-content>
                                        <v-list-item-subtitle>{{info.alias}}</v-list-item-subtitle>
                                        <v-list-item-title>{{form[info.name]}}</v-list-item-title>                                        
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                                    

                                    <!-- <v-text-field disabled label="Previous. Login Date" outlined v-model="form.prev_login_date"></v-text-field>
                                    <v-text-field disabled label="Current Login Date" outlined v-model="form.curr_login_date"></v-text-field>
                                    <v-text-field disabled label="Created Date" outlined v-model="form.created_date"></v-text-field>
                                    <v-text-field disabled label="Created By" outlined v-model="form.created_by"></v-text-field>
                                    <v-text-field disabled label="Created At" outlined v-model="form.created_at"></v-text-field>
                                    <v-text-field disabled label="Updated Date" outlined v-model="form.updated_date"></v-text-field>
                                    <v-text-field disabled label="Updated By" outlined v-model="form.updated_by"></v-text-field>
                                    <v-text-field disabled label="Updated At" outlined v-model="form.updated_at"></v-text-field> -->
                                <!-- </v-col> -->
                            <!-- </v-row> -->
                        </v-form>
                    </v-card>

                </v-tab-item>


            </v-tabs>
            
        </div>        
    </v-card>
        
</template>

<script>
//import axios from 'axios';
import ApCustomerService from "@/services/ApCustomerService"
import ApTransactionService from "@/services/ApTransactionService"
import ApRedemptionService from "@/services/ApRedemptionService"
import moment from 'moment'
import DlgMessage from '@/components/DlgMessage'
import DlgCustChangePassword from "@/components/DlgCustChangePassword";
import DlgCustChangePIN from "@/components/DlgCustChangePIN";
import DlgCustIssuance from "@/components/DlgCustIssuance";
import DlgCustAddress from "@/components/DlgCustAddress";
import DlgCustActivate from "@/components/DlgCustActivate";
import DlgConfirm from '@/components/DlgConfirm'
export default {
    components:{DlgCustChangePassword,DlgCustChangePIN,DlgMessage,DlgCustIssuance,DlgCustAddress,DlgCustActivate,DlgConfirm},
    data(){
        return{
            tabIndex:1,
            searchKeyList:[{code:"transaction_date",description:"Transaction Date"},{code:"process_date",description:"Process Date"},],
            txSearch:{dateFrom:"",dateTo:"",key:"process_date",limit:500,menu1: false,menu2: false,
                tblHeaders:[
                    { text:"Merchant Name",value: "merchant_name"  },
                    { text:"Outlet Name", value:"outlet_name" },
                    { text:"Member Id", value:"login_id" },
                    { text:"TX Date", value:"transaction_date" },
                    { text:"Code", value:"eg_code" },                
                    { text:"Reference No.", value:"reference_no" },
                    { text:"Amount", value:"process_amount" },
                    { text:"Points", value:"points" },
                    { text:"Process Ref #.", value:"process_reference_no" },
                ],

                tblBody:[],
                tblSearch:"",loadingFlag:false},
            searchRdKeyList:[{code:"redemption_date",description:"Redemption Date"},{code:"created_date",description:"Process Date"},],                
            rdSearch:{dateFrom:"",dateTo:"",key:"created_date",limit:500,menu1: false,menu2: false,
                tblHeaders:[
                    { text:"Process Ref #",value: "process_reference_no"  },
                    { text:"Redemption ID",value: "redemption_id"  },
                    { text:"Date.", value:"redemption_date" },                
                    { text:"Name", value:"full_name" },
                    { text:"State", value:"state" },
                    { text:"Cell Phone #", value:"cell_phone" },
                    { text:"Status", value:"redemption_status" },
                    { text:"Total Pts", value:"total_points" },
                    { text:"Total Item", value:"total_item" },
                    { text:"Total Qty", value:"total_quantity" },
                ],
                tblBody:[],tblSearch:"",loadingFlag:false},
            histSearch:{dateFrom:"",dateTo:"",key:"created_date",limit:500,menu1:false,menu2:false,                
                tblHeaders:[
                    { text:"Date",value: "created_date" },
                    { text:"Action",value: "caller"  },
                    { text:"By",value: "created_by"  },
                ],
                tblBody:[],tblSearch:"",loadingFlag:false},


            items: [
              {text:'Customers',disabled: false,href:'/customers',},
              {text:'Details',disabled: false,href:'#',},
              ],

            
            menus:[
                { title:"Activation", type:1, route:"/merchants/outlets/", icon:"mdi-check" },
                { title:"Change Password", type:2, route:"/merchants/edc/", icon:"mdi-lock" },
                { title:"Change PIN", type:3, route:"/merchants/edc/", icon:"mdi-alarm-panel" },
                // { title:"Add Address", type:4, route:"/merchants/outlets/", icon:"mdi-plus" },
                // { title:"Manual Point Issuance", type:5, route:"/merchants/edc/", icon:"mdi-sale" },
                // { title:"Manual Redemption", type:6, route:"/merchants/edc/", icon:"mdi-gift" },
            ],
            form:{login_id:"",customer_id:0},
            apiUrl:"",
            formRef:null,
            dataLimitList:[
                {code:500,description:'500 rows'},
                {code:1000,description:'1000 rows'},
                {code:2000,description:'2000 rows'},
                {code:5000,description:'5000 rows'},
            ],
            dlgCustAddressVisible:false,
            dlgVisible:{activate:false},
            addressList:[
                {alias:"house",attention_to:"A1",address1:"address1",address2:"address2",address3:"address3",city:"city",postal_code:"47500",state:"SELANGOR",country:"MALAYSIA",default:"1"},
                {alias:"office",attention_to:"A2",address1:"address1",address2:"address2",address3:"address3",city:"city",postal_code:"47500",state:"SELANGOR",country:"MALAYSIA",default:"0"},
            ],
            customer_address_id:0,
            memberParam:[],
            demographyFields: [
              {text:'Salutation',type:'select',field:'salutation',optionList:'mbr_salutation'},
              {text:'Gender',type:'select',field:'gender',optionList:'dmg_gender'},
              {text:'Income Range',type:'select',field:'income_range',optionList:'dmg_income_range'},
              {text:'Marital Status',type:'select',field:'marital_status',optionList:'dmg_marital_status'},
              {text:'Nationality',type:'select',field:'nationality',optionList:'dmg_nationality'},
              {text:'Occupation',type:'select',field:'occupation',optionList:'dmg_occupation'},
              {text:'Race',type:'select',field:'race',optionList:'dmg_race'},              
              ],
            systemInfo:[
                {"name":"prev_login_date","alias":"Previous Login Date"},
                {"name":"curr_login_date","alias":"Current Login Date"},
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],
        }
    },
    created(){
        this.form.customer_id = this.$route.params.customer_id;
        this.viewDetails();
        console.log('list address');
        this.listAddress();
        this.txSearch.dateFrom = moment(String(new Date())).format('YYYY-MM-01');
        this.txSearch.dateTo = moment(String(new Date())).format('YYYY-MM-DD');
        this.rdSearch.dateFrom = moment(String(new Date())).format('YYYY-MM-01');
        this.rdSearch.dateTo = moment(String(new Date())).format('YYYY-MM-DD');
        this.histSearch.dateFrom = moment(String(new Date())).format('YYYY-MM-01');
        this.histSearch.dateTo = moment(String(new Date())).format('YYYY-MM-DD');
        this.memberParam = JSON.parse(localStorage.getItem('memberParam'));
    },
    computed: {

    },
    methods:{
        async viewDetails(){
            try{
                ApCustomerService['view_customer'](this.form).then((res) => {
                    this.form = res.data.data;
                    for (const [key, value] of Object.entries(this.form)) {
                        if(value == null){
                            this.form[key] = "";
                        }
                    }                
                    
                }).catch((e)=>{
                    console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Customer',e.response.data.error_message);
                })
                ;
            } catch(e) {
                if(e){
                    this.$refs.dlgMsg.showDlgBox(true,'error','Customer','Unknown Error');
                }

            }

        },

        tabClickHandler(tabIndex){
            this.tabIndex = tabIndex;
        },
        frefresh(){
            window.location.reload()
        },
        async fsave(){
            this.form.login_id = ''
            var title = '';
            switch (this.tabIndex) {
                case 1:
                    this.apiUrl = "update_profile";
                    this.formRef = this.$refs.formAccount;
                    title = "Profile"
                    break;
                case 2:
                    this.apiUrl = "update_contact";
                    this.formRef = this.$refs.formContact;
                    title = "Contact"
                    break;
                case 4:
                    this.apiUrl = "update_membership";
                    this.formRef = this.$refs.formMembership;
                    title = "Membership"
                    break;                    
                case 5:
                    this.apiUrl = "update_demography";
                    this.formRef = this.$refs.formDemography;
                    title = "Demography"
                    break;                
                case 6:
                    this.apiUrl = "update_account";
                    this.formRef = this.$refs.formAccount;
                    title = "Account"
                    break;
                case 7:
                    this.apiUrl = "update_extra_info";
                    this.formRef = this.$refs.formExtraInfo;
                    title = "Extra Info"
                    break;                                
                default:
                    this.apiUrl = "";
                    break;
            }
            if(this.apiUrl!="") {
                if(this.formRef.validate()){
                    console.log(this.apiUrl);
                    console.log(this.formRef);
                    console.log(this.form);
                try{
                    ApCustomerService[this.apiUrl](this.form).then(() => {
                        this.$refs.dlgMsg.showDlgBox(true,'success','Update '+title,'Success.');
                    }).catch((e)=>{
                        console.log(e);
                        this.$refs.dlgMsg.showDlgBox(true,'error','Update '+title,e);
                    })
                    ;
                } catch(e) {
                    if(e){
                        this.$refs.dlgMsg.showDlgBox(true,'error','Customer','Unknown Error');
                    }
                }



                    // try {
                    //     const res = await axios.post(this.apiUrl,this.form)
                    //     console.log(res);
                    //     this.showNotification(title);


                    // } catch(e) {
                    //     //console.error(e.response.status);
                    //     //console.error(e.response.data.error_message);
                    //     this.error_message = e.response.data.error_message;
                    //     this.error_code = e.response.status;
                    // }
                }            
            }            

        },
        menuClick(type){
            switch (type) {
                case 1:
                    this.dlgVisible.activate = true;
                    this.$refs.dlgCustActivate.showDlg(this.form);
                    break;
                case 2:
                    //this.dlgChangePassword.showDialog = true;
                    //this.$refs.dlgMsg.showDlgBox(true,'error','TITLE','TEXT MESSAGE');
                    this.$refs.dlgChangePassword.showDlg(this.form.customer_id);
                    break;
                case 3:
                    this.$refs.dlgChangePIN.showDlg(this.form.customer_id);
                    break;
                case 5:
                    this.$refs.dlgCustIssuance.showDlg(this.form.customer_id);
                    break;            
                default:
                    
                    break;
            }
        },
        async listAddress(){
            console.log('address list');
            try{
                ApCustomerService['list_customer_address'](this.form).then((res) => {
                    this.addressList = res.data.data;                    
                }).catch((e)=>{
                    console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Customer',e.response.data.error_message);
                })
                ;
            } catch(e) {
                if(e){
                    this.$refs.dlgMsg.showDlgBox(true,'error','Customer','Unknown Error');
                }
            }
           
        },

        dlgCustAddressShow(index){
            this.dlgCustAddressVisible = true;
            if(index>-1){
                this.customer_address_id = parseInt(this.addressList[index].customer_address_id);
                this.$refs.dlgCustAddress.dlgShow(this.form.customer_id,this.customer_address_id);
            }else{
                this.$refs.dlgCustAddress.dlgShow(this.form.customer_id,0);
            }
            
        },
        dlgCustAddressClose(){
            this.dlgCustAddressVisible = false;
            this.listAddress();
        },

        dlgCustClose(cname){
            this.dlgVisible[cname] = false;
        },


        dlgCustAddressDelete(index){
            this.customer_address_id = parseInt(this.addressList[index].customer_address_id);
            this.$refs.dlgCustAddress.dlgDelete(this.form.customer_id,this.customer_address_id);
            this.addressList.splice(index,1)
        },

        async searchCustTransaction(){
                let title = 'Search Transaction'
                let formSearch = {
                    login_id:"",
                    search_key:this.txSearch.key,
                    search_word:"",
                    start_date:this.txSearch.dateFrom,
                    end_date:this.txSearch.dateTo,
                    data_limit:500,
                    customer_id:this.form.customer_id
                }
                if(this.$refs.formSearchCustTransaction.validate()){
                    this.txSearch.loadingFlag = true
                    try{
                    ApTransactionService.search_transaction(formSearch).then((res) => {
                        console.log(res);
                        this.txSearch.tblBody = res.data.data;
                        this.txSearch.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                        this.txSearch.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.txSearch.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                  
                }          
                }
            },

            async searchCustRedemption(){
                let title = 'Search Redemption'
                let formSearch = {
                    login_id:"",
                    search_key:this.rdSearch.key,
                    search_word:"",
                    start_date:this.rdSearch.dateFrom,
                    end_date:this.rdSearch.dateTo,
                    data_limit:500,
                    customer_id:this.form.customer_id
                }

                if(this.$refs.formSearchCustRedemption.validate()){
                    this.loadingFlag = true
                    try{
                    ApRedemptionService.search_redemption(formSearch).then((res) => {
                        //console.log(res);
                        this.rdSearch.tblBody = res.data.data;
                        this.rdSearch.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                        this.rdSearch.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                  
                }          
                }
            },
            openTxDetail(value){
                let routeData = this.$router.resolve({ 
                    name: 'point_transaction_details', 
                    params: { 
                        process_reference_no: value.process_reference_no,
                    } 
                })
                window.open(routeData.href,'_blank')
                // this.$router.push({ name: 'point_transaction_details', params: { process_reference_no: value.process_reference_no } })
            },
            openRdDetail(value){
                let routeData = this.$router.resolve({ 
                    name: 'redemption_details', 
                    params: { 
                        process_reference_no: value.process_reference_no,
                        redemption_id:value.redemption_id
                    } 
                })
                window.open(routeData.href,'_blank')
                // this.$router.push({ name: 'point_transaction_details', params: { process_reference_no: value.process_reference_no } })
            },
            async searchCustHistory(){
                let title = 'Search Customer History'
                let formSearch = {
                    login_id:"",
                    start_date:this.histSearch.dateFrom,
                    end_date:this.histSearch.dateTo,
                    data_limit:500,
                    customer_id:this.form.customer_id
                }

                if(this.$refs.formSearchCustHistory.validate()){
                    this.loadingFlag = true
                    try{
                    ApCustomerService.search_history(formSearch).then((res) => {
                        //console.log(res);
                        this.histSearch.tblBody = res.data.data;
                        this.histSearch.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                        this.histSearch.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    alert(e);
                    this.histSearch.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                  
                }          
                }
            },

        async renewal(){
            if (await this.$refs.confirm.open("Confirmation","Renew membership for this customer?")){
                let request = {login_id:"",customer_id:this.form.customer_id};
                let title = "Membership Renewal";
                try{
                    ApCustomerService['renew_membership'](request).then((res) => {                    
                        this.$refs.dlgMsg.showDlgBox(true,'success',title,res.data.error_message);
                        this.form.mbr_expiry_date = res.data.mbr_expiry_date;
                    }).catch((e)=>{
                        if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Customer',e.response.data.error_message);
                    })
                    ;
                } catch(e) {
                    if(e){
                        this.$refs.dlgMsg.showDlgBox(true,'error','Customer','Unknown Error');
                    }
                }

            }

           
        },


    }
}
</script>
