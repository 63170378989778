<template>
    <v-dialog max-width="500px"  v-model="show">
        <DlgMessage ref="dlgMsg"></DlgMessage>
        <v-card>
            <v-toolbar flat dark color="panel1">
                <v-toolbar-title>Manual Point Issuance</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="visible= false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-5">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-form ref="mainForm">
                            <v-select
                                label="Merchant"
                                outlined
                                dense
                                :items="dataList['merchantList']"
                                item-text="merchant_name"
                                item-value="merchant_id"
                                v-model = "form.merchant_id"
                                v-on:change="form.edc_login_id='' "
                            ></v-select>
                            <v-select
                                label="EDC"
                                outlined
                                dense
                                :items="edcListFiltered"
                                item-text="outlet_name"
                                item-value="edc_login_id"
                                v-model = "form.edc_login_id"
                            ></v-select>
                            <v-select
                                label="Code"
                                outlined
                                dense
                                :items="egListFiltered"
                                item-text="eg_name"
                                item-value="eg_code"
                                v-model = "form.eg_code"
                            ></v-select>
                            <v-menu v-model="dpicker.menu" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.transaction_date"
                                        label="Transaction Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="dpicker.dateCurr" @change="dpicker.menu = false"></v-date-picker>
                            </v-menu>

                            <v-text-field 
                                label="Purchase Amount / Points" 
                                outlined
                                dense
                                v-model="form.amount"  
                            ></v-text-field>
                            <v-text-field 
                                label="Reference No." 
                                outlined 
                                dense
                                v-model="form.reference_no" 
                            ></v-text-field>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark><mdi-check></mdi-check></v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="visible = false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import DlgMessage from '@/components/DlgMessage'
import ApTransactionService from '@/services/ApTransactionService'
import moment from 'moment'
export default {
    name: "DlgCustIssuance",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      },
      edcListFiltered(){
          return this.dataList['edcList'].filter(item => item.merchant_id === this.form.merchant_id)
      },
      egListFiltered(){          
          return this.dataList['egEdcList'].filter(item => item.edc_login_id === this.form.edc_login_id)
      }

      
    },    
  data() {
    return {
      message: "",
      customer_id:0,
      form:{
            login_id:"",
            customer_id:0,
            eg_code:"",
            merchant_id:0,
            edc_login_id: "",
            transaction_date: "",
            reference_no: "refno0001",
            amount: 0,
            quantity: 0,
          },
        dataList:{edcList:[],egEdcList:[]},
        dpicker:{dateCurr:"",menu: false,},
      rules: {
            required: value => !!value || 'Required.',
            counter: value => value.length == 6 || 'Need 6 digit',
            digit: value=> /[0-9][0-9][0-9][0-9][0-9][0-9]/.test(value) || 'Number only',
      },
    };
  },
  async created(){
      this.form.transaction_date = moment(String(new Date())).format('YYYY-MM-DD');
      this.getParameter();

  },
  methods: {
    showDlg(customer_id){
        this.visible = true;
        this.form.customer_id = customer_id;
        console.log(customer_id);
    },
    async save(){
        if(this.$refs.mainForm.validate()) {

            let title = 'Manual Point Issuance';
            try{
                ApTransactionService.issue_point(this.form).then((res) => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Manual Point Issuance','Success. Point earned:' + res.data.points);
                }).catch((e)=>{
                        //console.log(e.response);
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                })
                    ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','title','Unknown Error');
            }            
        }

    },
    async getParameter(){
        let title = 'Get Parameter';
        try{
            ApTransactionService.get_issuance_parameter(this.form).then((res) => {
                this.dataList = res.data;
            }).catch((e)=>{
                    //console.log(e.response);
                if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
                ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','title','Unknown Error');
        } 
    }    

  }
};

</script>
