import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApCustomerService {


    list_customer() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_customer/list_customer", form);

    }
    
    view_customer(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/view_customer", form);

    }

    insert_customer(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/insert_customer", form);

    }

    update_customer(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/update_customer", form);

    }    
    update_profile(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/update_profile", form);

    }
    
    update_contact(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/update_contact", form);

    }

    
    update_demography(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/update_demography", form);

    }
    
    
    update_account(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/update_account", form);

    }

    update_extra_info(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/update_extra_info", form);

    }
    update_membership(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/update_membership", form);

    }    

    list_customer_address(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/list_customer_address", form);

    }    

    
    address_param() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        console.log(form);
        return axios.post("ap_customer/address_param", form);

    }    


    search_customer_custom(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/search_customer_custom", form);

    }    


    delete_customer_address(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/delete_customer_address", form);

    }    


    view_customer_address(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/view_customer_address", form);

    }    


    update_customer_address(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/update_customer_address", form);
    }

    insert_customer_address(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/insert_customer_address", form);
    }    

    change_password(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/change_password", form);
    }    
    
    change_pin(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/change_pin", form);
    }    

    search_customer(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/search_customer", form);

    }    

    activate_customer(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/activate_customer", form);

    }    

    
    search_history(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/search_history", form);

    }        

    renew_membership(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_customer/renew_membership", form);

    }        

}
    
 

export default new ApCustomerService();