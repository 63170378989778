import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApTransactionService {


    list_recent() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_transaction/list_recent", form);

    }

    view_transaction(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_transaction/view_transaction", form);

    }  
    
    search_transaction(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_transaction/search_transaction", form);

    }

    
    get_issuance_parameter(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_transaction/get_issuance_parameter", form);
    }

    issue_point(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_transaction/issue_point", form);

    }
    


}
    
 

export default new ApTransactionService();