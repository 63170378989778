<template>
    <v-dialog max-width="500px" v-model="show">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
            <v-toolbar flat dark color="panel1">
                <v-toolbar-title>Activation</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-5">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-form ref="mainForm">
                            <v-text-field 
                            v-if="show" autofocus
                            label="Member Status" 
                            outlined 
                            counter
                            v-model="params.mbr_status"
                            readonly
                        ></v-text-field>

                            <v-text-field 
                            v-if="show" autofocus
                            label="Activation Code" 
                            outlined 
                            :rules="[rules.required, rules.counter, rules.digit]"
                            counter
                            maxlength="6"
                            v-model="form.verification_code"
                            readonly
                        ></v-text-field>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Activate
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import DlgMessage from '@/components/DlgMessage'
import ApCustomerService from '@/services/ApCustomerService'
export default {
    name: "DlgCustChangePIN",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    },    
  data() {
    return {
      message: "",
      customer_id:0,
      form:{login_id:"",customer_id:0,verification_code:"",activation_code:""},
      params:[],
      rules: {
            required: value => !!value || 'Required.',
            counter: value => value.length == 6 || 'Need 6 digit',
            digit: value=> /[0-9][0-9][0-9][0-9][0-9][0-9]/.test(value) || 'Number only',
      },
      errorFlag:0,
    };
  },
  methods: {
    showDlg(params){
        //this.visible = true;
        this.form.customer_id = params.customer_id;
        this.form.verification_code = params.verification_code;
        this.form.activation_code = params.verification_code;
        this.params = params;
        //console.log(customer_id);
    },
    async save(){
        if(this.$refs.mainForm.validate()) {
            let title = 'Activation';
            try{
                ApCustomerService.activate_customer(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success');
                    this.errorFlag = 0;
                }).catch((e)=>{
                    this.errorFlag = 1;
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                })
                    ;
            } catch(e) {
                this.errorFlag = 1;
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','title','Unknown Error');
            } 

        }

    },
    dlgClose(){
        if(!this.errorFlag){
            this.show = false;
        }            
    },

  }
};

</script>
