<template>
    <v-dialog max-width="500px" v-model="show">
        <DlgMessage ref="dlgMsg"></DlgMessage>
        <v-card>
            <v-toolbar flat dark color="panel1">
                <v-toolbar-title>Change Password</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="visible = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-5">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-form ref="mainForm">
                            <v-text-field 
                            ref="inputPassword"
                            v-if="show" autofocus
                            type="password" 
                            label="New Password" 
                            outlined 
                            :rules="inputRules"
                            counter
                            maxlength="25"
                            v-model="form.new_password"
                        ></v-text-field>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-content-save</v-icon>
                    Save
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="visible = false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import DlgMessage from '@/components/DlgMessage'
import ApCustomerService from '@/services/ApCustomerService'
export default {
    name: "DlgCustChangePassword",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    },    
  data() {
    return {
      message: "",
      customer_id:0,
      form:{login_id:"",customer_id:0,new_password:""},
      inputRules: [
             v => !!v || 'This field is required'
            ],
    };
  },
  methods: {
    showDlg(customer_id){
        this.visible = true;
        this.form.customer_id = customer_id;
        console.log(customer_id);
    },
    async save(){
        if(this.$refs.mainForm.validate()) {

            try{
                ApCustomerService.change_password(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Change Password','Success');
                }).catch((e)=>{
                        //console.log(e.response);
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Change Password',e.response.data.error_message);
                })
                    ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','Change Password','Unknown Error');
            } 

        }

    }

  }
};

</script>
